// noinspection JSUnresolvedVariable

const {
    parseClone,
    makeImageClone,
    findMinimalOrMaximalPrice,
    findFlatsExactLocale,
    buildNeededData,
    imageAddFields,
    minQuantityFieldAdd
} = require("./helpers")

const settingConstants = require("./constants")
const {Get_Exact_Core_Config} = require("./SettingsController")
const {Get_Needed_Attribute} = require("./AttributeController")
const {featuredOrNewAggregate} = require("./aggregations")
const {filterBy} = require("../scripts/filterBy")
const {colorType} = require("../services/color");
// const defaultPicture = require("../images/defaultpic.webp").default;
const handlePriceCommasFormat = (price) => {
    return String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
const handleAmdPrice = ({code: currCode}, price = 0) => {
    const currentCurrCodeIsAmd = currCode.toLowerCase() === "amd";
    if (!currentCurrCodeIsAmd) {
        return Number(price).toFixed(2)
    }
    return +price
}

const productCurrencyToggle = (el, selectedRate, locale) => {
    const {exchange_rate: {rate = 1} = {}} = selectedRate || {}
    const handlePriceName = (priceKey) => {
        const findItem = el?.flats?.find(ii => ii.locale === locale)?.[priceKey];
        if (!!Number(findItem)) {
            return findItem * rate
        }
        return null
    }

    return {
        ...el,
        flats: [
            {
                ...el.flats?.find(ii => ii.locale === locale),
                selectedRate,
                price: handlePriceName("price"),
                min_price: handlePriceName("min_price"),
                max_price: handlePriceName("max_price"),
                special_price: handlePriceName("special_price")
            }
        ]
    }
}

const specialPriceTimeToggle = (product, selectedRate, onlyForAmd) => {
    const { flats: [{ special_price, special_price_from, special_price_to } = {}] = [] } = product || {};
    if (!special_price) return null
    const timeIntoSeconds = date => new Date(date).getTime() / 1000
    const amdFormatPrice = handleAmdPrice(selectedRate, special_price)
    const formattedSpPrice = handlePriceCommasFormat(amdFormatPrice)
    const updatedPrice = onlyForAmd ? amdFormatPrice : formattedSpPrice
    const currTime = new Date().getTime() / 1000
    const from = timeIntoSeconds(special_price_from)
    const to = timeIntoSeconds(special_price_to)
    if (special_price_from && !special_price_to) {
        if (from <= currTime) {
            return updatedPrice
        }
        return null
    } else if (special_price_to && !special_price_from) {
        if (currTime <= to) {
            return updatedPrice
        }
        return null
    } else if (special_price_to && special_price_from) {
        if (from <= currTime && currTime <= to) {
            return updatedPrice
        }
        return null
    }
    return updatedPrice
}

const addMinQuantityController = async (models, productArray) => {
    const minQuantityAttribute = await Get_Needed_Attribute(models, "min_qty", "min_qty")
    const minQuantityAttrValue = await models.product_attribute_values.find({attribute_id: minQuantityAttribute?.min_qty[0]?.id})
    return minQuantityFieldAdd(productArray, minQuantityAttrValue)
}

const allowOutOfStock = async (models) => await Get_Exact_Core_Config(models, settingConstants.Out_Of_Stock)
const outOfStockProductMatch = [
    {$and: [
            { "type": "configurable" },
            { $expr: { $gt: [{
                        $size: {
                            $filter: {
                                input: "$variants",
                                as: "variant",
                                cond: { $gt: ["$$variant.qty", [0]] }
                            }
                        }}, 0]}}
        ]},
    { "type": "downloadable" },
    {
        "type": "bundle",
        $expr: {
            $not: {
                $allElementsTrue: {
                    $map: {
                        input: "$bundle_options",
                        as: "option",
                        in: {
                            $allElementsTrue: {
                                $map: {
                                    input: "$$option.bundle_option_products",
                                    as: "product",
                                    in: { $eq: ["$$product.product_qty", 0] }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    { "qty": { $gt: 0 } }
]

const getNewFeatured = async (models, locale, selectedRate, outStockAllow) => {
    const baseMatch = {
        "flats.locale": locale,
        "flats.status": 1
    };
    if (!Number(outStockAllow)) {
        baseMatch["$or"] = outOfStockProductMatch;
    }
    return await models.products_v2.aggregate([
        {$match: baseMatch},
        {$sort: {"flats.0.product_number": 1}},
        {
            $facet: {
                newProducts: [{$match: {"flats.new": 1}}, {$limit: 12}],
                featuredProducts: [{$match: {"flats.featured": 1}}, {$limit: 12}],
                thirdSectionProducts: [{$match: {"flats.third_section": 1}}, {$limit: 12}],
            }
        },
        {
            $project: {
                combinedProducts: {$concatArrays: ["$newProducts", "$featuredProducts", "$thirdSectionProducts"]}
            }
        },
        {$unwind: "$combinedProducts"},
        {$replaceRoot: {newRoot: "$combinedProducts"}},
        {
            $addFields: {
                flats: {
                    $filter: {
                        input: "$flats",
                        as: "flat",
                        cond: {$eq: ["$$flat.locale", locale]}
                    }
                },
                selectedRate: +selectedRate?.exchange_rate?.rate || 1
            }
        },
        {
            $addFields: {
                flats: {
                    $map: {
                        input: "$flats",
                        as: "flat",
                        in: {
                            $mergeObjects: [
                                "$$flat",
                                {
                                    price: {$multiply: ["$$flat.price", "$selectedRate"]},
                                    min_price: {$multiply: ["$$flat.min_price", "$selectedRate"]},
                                    max_price: {$multiply: ["$$flat.max_price", "$selectedRate"]},
                                    special_price: {$multiply: ["$$flat.special_price", "$selectedRate"]}
                                }
                            ]
                        }
                    }
                }
            }
        },
    ])
}
const getInfiniteScrollValue = async (models) => await Get_Exact_Core_Config(models, settingConstants.Infinite_Scroll)

const handleConfigPrice = (element, selectedRate, selectedLocale) => {
    const minimalPrice = Math.min(...new Set(element.variants.map(el => {
        const variantProduct = productCurrencyToggle(el, selectedRate, selectedLocale);
        const { flats: [ { min_price, price } ] } = variantProduct || {};
        return min_price || price
    })));
    return {
        ...element,
        flats: [{
            ...element.flats[0],
            min_price: Number(minimalPrice),
            price: Number(minimalPrice),
        }],
    }
}

const bundleProductHandle = async ({product, models, selectedRate, locale, outStockAllow}) => {
    if (product && product.type === "bundle") {
        const flatIds = [...new Set(product?.bundle_options?.map(option => {
            return option?.bundle_option_products?.map(option_product => {
                return option_product?.product_id
            })
        }).flat())]
        const productsFinderQueryHandle = {
            "id": {$in: flatIds},
            "flats.status": {$in: ["1", 1]},
            ...(() => {
                if (String(outStockAllow) !== "0") return {}
                return {"qty": {$gt: 0}}
            })(),
        }
        const bundleProdArr = await models.products_v2.find(productsFinderQueryHandle)
        if (bundleProdArr.length === 0) return {}
        const updateWithCurrency = parseClone(bundleProdArr)?.map(el => productCurrencyToggle(el, selectedRate, locale))
        const findBundleProducts = findFlatsExactLocale(updateWithCurrency, locale);
        const sortedBundleOptions = product?.bundle_options?.sort((a, b) => a?.sort_order - b?.sort_order)
        return {
            bundle_options: sortedBundleOptions?.map(({ bundle_option_products, ...option }) => {
                const sortedBundleOptionProducts = bundle_option_products?.sort((a, b) => a?.sort_order - b?.sort_order)
                return {
                    ...option,
                    option_product_info: sortedBundleOptionProducts?.map(option_product => {
                        const findProduct = parseClone(findBundleProducts)?.find(bundleProd => {
                            return bundleProd?.id === option_product?.product_id
                        })
                        if (findProduct) {
                            const {
                                flats: [{
                                    name: productName = "",
                                    min_price: productMinPrice,
                                    price: productPrice = 0
                                } = {}] = [],
                                qty: [productQty],
                            } = findProduct || {};
                            const finalProductQty = String(outStockAllow) !== "0" ? 5000 : productQty
                            const {symbol: currSymbol} = selectedRate || {}
                            const amdPriceToggled = handleAmdPrice(selectedRate, productMinPrice)
                            const priceFormatted = handlePriceCommasFormat(amdPriceToggled)
                            return {
                                id: option_product?.id,
                                is_default: option_product?.is_default,
                                name: `${productName} + ${currSymbol}${priceFormatted}`,
                                productQty: finalProductQty,
                                optionDefaultQty: +option_product?.qty > +finalProductQty ? finalProductQty : option_product?.qty,
                                bundleOptionId: option_product?.product_bundle_option_id,
                                price: productMinPrice ?? productPrice
                            }
                        }
                        return false
                    })
                        ?.filter(elem => elem)
                        ?.map((defaultItemSetter, index, array) => {
                            const hasDefaultItem = array?.some(optProductSome => Boolean(+optProductSome?.is_default));
                            if (hasDefaultItem) return defaultItemSetter;
                            return {
                                ...defaultItemSetter,
                                is_default: index === 0 ? 1 : defaultItemSetter?.is_default
                            }
                        }),
                }
            })?.filter(elem => elem.option_product_info.length > 0),
        }
    }
    return {}
}

/*** NEW FEATURED/NEW PRODUCTS HOME PAGE ***/
const Get_New_And_Featured_Products = async (opts, models) => {
    const {locale, selectedRate, outStockAllow, isMobile} = opts;

    const filterUniqueObjects = (products, type) => {
        const result = []
        const ids = new Set(products.filter(el => el.flats[0][type] === 1).map(el => el.id))

        for (let elem of ids) {
            result.push(products.find(el => el.id === elem))
        }

        return result
    }

    try {
        const fulfilledNewFeaturedProducts = await getNewFeatured(models, locale, selectedRate, outStockAllow)
        const allProducts = fulfilledNewFeaturedProducts.map(el => !!el.variants.length ? handleConfigPrice(el, selectedRate, locale) : el)

        const newProducts = await addMinQuantityController(models, filterUniqueObjects(allProducts, "new"))
        const featuredProducts = await addMinQuantityController(models, filterUniqueObjects(allProducts, "featured"))
        const thirdSectionProducts = await addMinQuantityController(models, filterUniqueObjects(allProducts, "third_section"))

        const allBlockTitles = await models.attributes.find({code: {$in: ["new", "featured", "third_section"]}});
        const {translations: blockIntl_new} = allBlockTitles[0]
        const {translations: blockIntl_featured} = allBlockTitles[1]
        const {translations: blockIntl_third} = allBlockTitles[2] || {}
        const {name: translatedBlockTitleNew = ""} = blockIntl_new.find(el => el.locale === locale) || {}
        const {name: translatedBlockTitleFeatured = ""} = blockIntl_featured.find(el => el.locale === locale) || {}
        const {name: translatedBlockTitleThird = ""} = blockIntl_third?.find(el => el.locale === locale) || {}
        const productFinalResultHandle = async (productList, blockTitle) => {
            return parseClone(await Promise.all(productList.map(async elem => {
                const priceHandler = async () => {
                    if (elem.type === "bundle") {
                        const bundle_options = await bundleProductHandle({
                            product: elem,
                            models,
                            locale,
                            selectedRate,
                            outStockAllow,
                        })
                        const optionQuantity = bundle_options?.bundle_options?.map(({option_product_info}) => {
                            return option_product_info?.find(findItem => !!findItem?.is_default) || {}
                        })
                        const totalPrice = optionQuantity?.reduce((total, { optionDefaultQty, price }) => {
                            return total + optionDefaultQty * price;
                        }, 0);
                        return {
                            price: handlePriceCommasFormat(handleAmdPrice(selectedRate, totalPrice)),
                            special_price: null,
                            min_price: handlePriceCommasFormat(handleAmdPrice(selectedRate, totalPrice)),
                        }
                    }
                    return {
                        price: handlePriceCommasFormat(handleAmdPrice(selectedRate, elem.flats[0].price)),
                        special_price: specialPriceTimeToggle(elem, selectedRate),
                        min_price: handlePriceCommasFormat(handleAmdPrice(selectedRate, elem.flats[0].min_price)),
                    }
                }
                return {
                    ...elem,
                    flats: [{
                        ...elem.flats[0],
                        name: elem.flats[0].name ?? "",
                        min_qty: elem.flats[0].min_qty ?? 0,
                        ...(await priceHandler()),
                        short_description: elem.flats[0].short_description?.replace(/<\/?[^>]+>/gi, "")
                    }],
                    block_title: blockTitle,
                    images: elem.images.length > 0
                        ? elem.images.map(el => `/storage/${isMobile ? "big_medium" : "small_large"}/${el}`)
                        : ["/_next/static/media/defaultpic.119a8e64.webp"]
                }
            })));
        }
        return {
            newProducts: await productFinalResultHandle(newProducts, translatedBlockTitleNew),
            featuredProducts: await productFinalResultHandle(featuredProducts, translatedBlockTitleFeatured),
            thirdSectionProducts: await productFinalResultHandle(thirdSectionProducts, translatedBlockTitleThird)
        };
    } catch (err) {
        throw err;
    }
}

const getSearchProducts = async (models, locale, query, selectedRate, defaultLngCode) => {
    const allowResponse = await allowOutOfStock(models);
    const baseMatch = {}
    if (!Number(allowResponse)) {
        baseMatch["$or"] = outOfStockProductMatch
    }
    const products = await models.products_v2.find(baseMatch);
    const removeAllSpaces = string => {
        return string?.toLowerCase().replace(/\s+/g, '');
    }
    const removeHtmlTags = string => {
        return string?.toLowerCase().replace(/<\/?[^>]+>/g, '');
    }
    const updatedProducts = products
        .filter(({flats, sku: prSku} = {}) => {
            const handleExistFlat = (eachKey) => [...new Set(flats.filter(flat => flat?.[eachKey]))]
            if (query) {
                const productQueryLower = removeAllSpaces(query);
                const productNameLower = [...new Set(handleExistFlat("name").map(({name: prName}) => removeAllSpaces(prName)))]
                const productSkuLower = removeAllSpaces(prSku);
                const productDescLower = [...new Set(handleExistFlat("description")
                    .map(({description: prDesc = ""}) => removeAllSpaces(removeHtmlTags(prDesc))))]
                const productShortDescLower = [...new Set(handleExistFlat("short_description")
                    .map(({short_description: prShortDesc = ""}) => removeAllSpaces(removeHtmlTags(prShortDesc))))]
                const existFilteredElem = productInfo => {
                    return productInfo.some(eachInfo => eachInfo.includes(productQueryLower))
                }
                return existFilteredElem(productNameLower)
                    || productSkuLower?.includes(productQueryLower)
                    || existFilteredElem(productDescLower)
                    || existFilteredElem(productShortDescLower)
            }
            return false
        })
            .filter(el => el.flats.some(elem => elem.status === 1));
        const updateQuantityRes = await addMinQuantityController(models, updatedProducts);
        const fillAllFlats = updateQuantityRes.map((eachPr) => {
            const eachPrObj = eachPr.toObject();
            const findFlatIntl = eachPrObj?.flats?.find(productFl => productFl?.locale === locale);
            const findFlatDefault = eachPrObj?.flats?.find(productFl => productFl?.locale === defaultLngCode) || {}
            if (!findFlatIntl || Object.keys(findFlatIntl).length === 0) {
                return {
                    ...eachPrObj,
                    flats: [findFlatDefault]
                }
            }
            const flatPropertyToggle = (key) => findFlatIntl?.[key] ?? findFlatDefault?.[key]
            const emptyFlatFill = {
                name: flatPropertyToggle("name"),
                min_qty: flatPropertyToggle("min_qty"),
                description: flatPropertyToggle("description"),
                status: flatPropertyToggle("status"),
                price: flatPropertyToggle("price"),
                min_price: flatPropertyToggle("min_price"),
                special_price: flatPropertyToggle("special_price"),
                short_description: flatPropertyToggle("short_description"),
            }
            return {
                ...eachPrObj,
                flats: [{...findFlatIntl, ...emptyFlatFill}]
            }
        })
        return await Promise.all(fillAllFlats.map(async el => {
            const searchedLocale = el?.flats?.[0]?.locale === locale ? locale : defaultLngCode
                const mainBody = {
                    ...productCurrencyToggle({
                        ...el,
                        flats: [{
                            ...el.flats[0],
                            price: handleAmdPrice(selectedRate, el?.flats?.[0]?.price),
                            special_price: specialPriceTimeToggle(el, selectedRate, true),
                            min_price: handleAmdPrice(selectedRate, el?.flats?.[0]?.min_price),
                        }],
                    }, selectedRate, searchedLocale),
                    images: el.images.length > 0
                        ? el.images.map(elem => `/storage/${elem}`)
                        : ["/_next/static/media/defaultpic.119a8e64.webp"]
                }
                if (!!el.variants.length) {
                    return {
                        ...handleConfigPrice(mainBody, selectedRate, searchedLocale),
                        flats: [{
                            ...el.flats[0],
                            price: handlePriceCommasFormat(handleConfigPrice(mainBody, selectedRate, searchedLocale)?.flats?.[0]?.price),
                            min_price: handlePriceCommasFormat(handleConfigPrice(mainBody, selectedRate, searchedLocale)?.flats?.[0]?.min_price),
                            special_price: el?.flats?.[0]?.special_price
                                ? handlePriceCommasFormat(
                                    specialPriceTimeToggle(
                                        handleConfigPrice(mainBody, selectedRate, searchedLocale),
                                        selectedRate,
                                        true
                                    )
                                ) : null
                        }]
                    }
                }
                if (el.type === "bundle") {
                    const bundle_options = await bundleProductHandle({
                        product: el,
                        models,
                        locale: searchedLocale,
                        selectedRate,
                        outStockAllow: allowResponse
                    })
                    const optionQuantity = bundle_options?.bundle_options?.map(({option_product_info}) => {
                        return option_product_info?.find(findItem => !!findItem.is_default) || {}
                    })
                    const totalPrice = optionQuantity?.reduce((total, { optionDefaultQty, price }) => {
                        return total + optionDefaultQty * price;
                    }, 0);
                    return {
                        ...mainBody,
                        flats: [{
                            ...el.flats[0],
                            price: handlePriceCommasFormat(handleAmdPrice(selectedRate, totalPrice)),
                            min_price: handlePriceCommasFormat(handleAmdPrice(selectedRate, totalPrice)),
                            special_price: null
                        }]
                    }
                }
                return {
                    ...mainBody,
                    flats: [{
                        ...el.flats[0],
                        price: handlePriceCommasFormat(handleAmdPrice(selectedRate, mainBody.flats[0].price)),
                        min_price: handlePriceCommasFormat(handleAmdPrice(selectedRate, mainBody.flats[0].min_price)),
                        special_price: specialPriceTimeToggle(el, selectedRate)
                    }]
                }
            }
        )
    )
}

/*** NEW SEARCH LOGIC ***/
const Get_Searched_Products = async (models, locale, query, selectedRate, defaultLngCode) => {
    try {
        return await getSearchProducts(models, locale, query, selectedRate, defaultLngCode)
    } catch (err) {
        throw err;
    }
}


/*** NEW PRODUCT INNER PAGE ***/
const Get_Product_For_Product_Inner_Page = async (productSlug, options, models) => {
    const {locale, selectedRate} = options;
    try {
        const allowResponse = await allowOutOfStock(models);
        const sku = await Get_Needed_Attribute(models, "sku", "sku_option")
        const shDesc = await Get_Needed_Attribute(models, "short_description", "short_description_option")
        const desc = await Get_Needed_Attribute(models, "description", "description_option")
        const findLocale = (attr) => attr?.translations?.find(({locale: trLocale}) => trLocale === locale)
        const productSkuName = findLocale(sku?.sku_option?.[0]) || "SKU";
        const productDescName = findLocale(desc?.description_option[0]) || "";
        const productShortDescName = findLocale(shDesc?.short_description_option[0]) || "";
        const data = await models.products_v2.find({
            "flats.locale": locale,
            "flats.url_key": productSlug,
            "flats.status": 1,
        })
        const res = findFlatsExactLocale(data, locale)
        const newRes = parseClone(res);
        const findVariantsWithLocale = newRes[0]?.variants ? findFlatsExactLocale(newRes[0]?.variants, locale) : [];
        const yoast_products = await models.yoast_products.find({locale, product_id: newRes[0]?.id})
        const [{
            yoast_facebook_description: yfd = "",
            yoast_facebook_title: yft = "",
            yoast_twitter_description: ytd = "",
            yoast_twitter_title: ytt = "",
            yoast_focus_keyword: yfk = ""
        } = {}] = parseClone(yoast_products);
        const updatedYoasts = [{
            yoast_facebook_description: !!yfd ? yfd : newRes[0]?.flats[0]?.meta_description,
            yoast_facebook_title: !!yft ? yft : newRes[0]?.flats[0]?.meta_title,
            yoast_twitter_description: !!ytd ? ytd : newRes[0]?.flats[0]?.meta_description,
            yoast_twitter_title: !!ytt ? ytt : newRes[0]?.flats[0]?.meta_title,
            yoast_focus_keyword: !!yfk ? yfk : newRes[0]?.flats[0]?.meta_keywords
        }]

        const updatedResWithCurr = [{
            ...newRes[0],
            ...(await bundleProductHandle({
                    locale,
                    product: newRes[0],
                    selectedRate,
                    models,
                    outStockAllow: allowResponse
                })
            ),
            variants: findVariantsWithLocale.map(el => productCurrencyToggle(el, selectedRate, locale)),
            yoast_products: updatedYoasts
        }]
        let variantsIndex = newRes[0]?.variants.map((el) => el.id);
        const variantsAttr = await models.product_attribute_values.find({product_id: {$in: variantsIndex}});
        const parsedVariantsAttr = parseClone(variantsAttr)
        const productSuperAttributes = await models.product_super_attributes.find({product_id: data[0]?.id})
        const parsedSuperAttributes = parseClone(productSuperAttributes)
        const superAttrIds = parsedSuperAttributes.map(item => item.attribute_id)
        const attributes = await models.attributes.find({id: {$in: superAttrIds}});
        const attributeOptionsAttr = await models.attribute_options.find({attribute_id: {$in: superAttrIds}});
        const parsedAttributes = parseClone(attributes)
        const parsedAttributesOptions = parseClone(attributeOptionsAttr)
        const productAttributeValues = await models.product_attribute_values.find({product_id: data[0]?.id});
        const parsedProductAttributeValues = parseClone(productAttributeValues)
        const attributeIds = parsedProductAttributeValues.map(item => item.attribute_id);
        const attributesDetail = await models.attributes.find({id: {$in: attributeIds}});
        const parsedAttributesDetail = parseClone(attributesDetail)
        const attributeOptions = await models.attribute_options.find({attribute_id: {$in: attributeIds}});
        const parsedAttributeOptions = parseClone(attributeOptions)
        const handleVariants = (id) => parsedVariantsAttr.filter((variant) => variant.product_id === id);
        const attributesRes = parsedAttributes.map((attribute) => {
            const matchingOptions = parsedAttributesOptions.filter((option) => option.attribute_id === attribute.id);
            const attributeOptions = matchingOptions.map((option) => ({
                ...option,
                attribute: {
                    ...attribute,
                    translations: [attribute?.translations?.find(({locale: itemLoc}) => itemLoc === locale) || {}]
                },
            }));

            return {
                ...attribute,
                attribute_options: attributeOptions.map((elem) => {
                    return {
                        ...elem,
                        swatch_value: colorType(elem.swatch_value),
                        colorsStyle: elem.swatch_value ?? null,
                        translations: [elem?.translations?.find(({locale: itemLoc}) => itemLoc === locale) || {}]
                    }
                }).sort(({sort_order: sortA}, {sort_order: sortB}) => sortA - sortB),
                translations: attribute?.translations?.filter(({locale: itemLoc}) => itemLoc === locale) || []
            };
        });
        const resultAttributes = attributesRes.map((elem) => {
            const matchingOptions = parsedSuperAttributes.filter((option) => {
                return option.attribute_id === elem.id;
            });
            return {
                attributes_values: [elem],
                ...matchingOptions[0]
            }
        })
        const updatedResultAttributes = resultAttributes.map(({attribute_id, product_id, _id, ...attributes}) => {
            const {attributes_values: [{attribute_options}], ...selectedAttributeObj} = attributes;
            const [selectedAttribute] = Object.keys(selectedAttributeObj);
            return {
                ...attributes,
                attribute_options: attribute_options.filter(({id}) => attributes[selectedAttribute].some(el => el === id))
            }
        })
        // const exceptArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 29]
        const codesArray = [
            "sku", "name", "url_key", "tax_category_id", "new", "featured", "visible_individually", "status",
            "short_description", "description", "price", "cost", "special_price", "special_price_from",
            "special_price_to", "meta_title", "meta_keywords", "meta_description", "third_section",
            "min_qty", "guest_checkout", "product_number"
        ]


        const filterDetails = (arr) => {
            const filteredArray = arr.filter(elem => {
                const findItem = parsedAttributesDetail.find(ii => ii.id === elem.attribute_id);
                return codesArray.every(everyIt => everyIt !== findItem?.code)
            })
            return filteredArray.map(item => {
                const attribute = parsedAttributesDetail.filter(attr => attr.id === item.attribute_id).map(el => {
                    return {
                        ...el,
                        ...el.translations.find(elem => elem.locale === locale)
                    }
                });
                const options = parsedAttributeOptions.filter(option => option.attribute_id === item.attribute_id);
                return {
                    ...item,
                    attribute,
                    attribute_options: options
                };
            }).map(el => {
                const attributeOptionsFinder = (value) => {
                    return el.attribute_options.find(elem => String(elem.id) === String(value))?.translations.find(el => el.locale === locale)?.label
                }
                const attributeTypeHandler = {
                    select: attributeOptionsFinder(el.integer_value),
                    //TODO!!! Why we need this? text and textarea does not have any attribute options to find it !!
                    // text: attributeOptionsFinder(el.text_value),
                    // textarea: attributeOptionsFinder(el.text_value),
                    text: el.text_value,
                    textarea: el.text_value,
                    datetime: el.datetime_value,
                    date: el.date_value,
                    price: el.float_value ? `${(+el.float_value).toFixed(2)}$` : el.float_value,
                    boolean: el.boolean_value,
                    multiselect: el.text_value?.split(',').map(value => attributeOptionsFinder(value)).filter(elem => !!elem).join(', '),
                    checkbox: el.text_value?.split(',').map(value => attributeOptionsFinder(value)).filter(elem => !!elem).join(', '),
                    image: el.text_value,
                    file: el.text_value
                }

                return {
                    ...el,
                    attribute_name: el.attribute[0]?.name || el.attribute[0]?.code || null,
                    attribute_value: attributeTypeHandler[el.attribute[0]?.type] || null
                }
            })
            .filter(el => +el.attribute?.[0]?.is_visible_on_front === 1 && !!el.attribute_value)
        }

        if (!res || res.length === 0) {
            return []
        }
        const modifiedRes = await Promise.all(updatedResWithCurr.map(async (elem) => {
            try {
                const minQuantityVal = await addMinQuantityController(models, elem.variants);
                const [item] = await addMinQuantityController(models, [elem])
                const productOptions = new Promise((resolve, reject) => {
                    models.product_options.find({product_id: item.id}).exec((err, productOptions) => {
                        if (err) {
                            reject(err);
                            return;
                        }
                        models.product_option_values.find().exec((err, productOptionValues) => {
                            if (err) {
                                reject(err);
                                return;
                            }
                            const options = productOptions.map(option => {
                                const children = productOptionValues
                                    .filter(value => value.product_option_id === option.id)
                                    .map(value => {
                                        let label = value.label;
                                        if (value.translations && value.translations.length > 0) {
                                            const translation = value.translations.find(trans => trans.locale === locale);
                                            if (translation) {
                                                label = translation.label;
                                            }
                                        }
                                        return {
                                            id: value.id,
                                            label: label,
                                            admin_name: value.admin_name,
                                            price: value.price,
                                            sort_order: value.sort_order
                                        };
                                    });

                                return {
                                    id: option.id,
                                    title: option.title,
                                    product_id: option.product_id,
                                    required: option.required,
                                    type: option.type,
                                    children: children
                                };
                            });

                            resolve(options);
                        });
                    });
                });
                const variantIdExistToggle = () => {
                    const {qty: [maxVariantQuantity = 0] = []} = item.variants
                        ?.find(({id: variantId}) => variantId === Number(item.additional?.default_variant_id)) || {};
                    return maxVariantQuantity;
                }
                const productElementOptions = {
                    sku_option: productSkuName,
                    short_description_option: productShortDescName,
                    description_option: productDescName,
                    details: filterDetails(parsedProductAttributeValues),
                    attributes: updatedResultAttributes,
                    product_options: JSON.parse(JSON.stringify(await productOptions)),
                }

                item.variants = minQuantityVal.map((el) => {
                    el.product_attribute_values = handleVariants(el?.id);
                    el.details = filterDetails(el?.product_attribute_values);

                    return {
                        ...el,
                        maxQty: variantIdExistToggle(),
                        min_qty: item.flats[0].min_qty || 1,
                        ...productElementOptions
                    };
                });
                return {
                    ...item,
                    ...productElementOptions
                };
            } catch (error) {
                console.error(error);
                return elem;
            }
        }));

        if (!modifiedRes || modifiedRes?.length === 0) return {notFound: true}
        const updateQuantityRes = await addMinQuantityController(models, modifiedRes)
        const updatedWithCurrProduct = updateQuantityRes.map(curr => productCurrencyToggle(curr, selectedRate, locale))
        return updatedWithCurrProduct.map((element) => {
            const replaceDesc = (str) => {
                if (!str) return str;
                const tableRegex = /<table[\s\S]*?<\/table>/g;
                const tables = str.match(tableRegex);

                if (!tables) return str;

                tables.forEach(table => {
                    const wrapper = `<div className="table_wrapper" style="overflow: scroll">${table}</div>`;
                    str = str.replace(table, wrapper);
                });
                return str;
            };

            const stripTags = (html) => {
                return html?.replace(/<[^>]*>/g, '');
            }
            const minimalPriceHandle = priceKey => {
                if (element.type === "bundle") {
                    return element.flats[0][priceKey]
                }
                if (!!element.variants.length) {
                    return handlePriceCommasFormat(
                        handleAmdPrice(selectedRate, Math.min(
                            ...new Set(element.variants.map(el => el.flats[0].min_price))
                        ))
                    )
                }
                return handleAmdPrice(selectedRate, element.flats[0][priceKey]);
            }

            const translatedDownloadableLinks = (elemKey, downloadable_id_key) => element[elemKey].map((each_link) => {
                const {exchange_rate: {rate = 1} = {}} = selectedRate || {}
                const currTranslation = each_link.translations.find(
                    trIntl => trIntl.locale === locale && each_link.id === trIntl[downloadable_id_key]
                );
                if (currTranslation) {
                    return {
                        ...each_link,
                        title: currTranslation.title,
                        price: handleAmdPrice(selectedRate, each_link.price * rate)
                    }
                }
                return each_link
            })

            return {
                ...element,
                attributes: updatedResultAttributes,
                images: element.images.length > 0
                    ? element.images.map(elem => `/storage/${elem}`)
                    : ["/_next/static/media/defaultpic.119a8e64.webp"],
                flats: [{
                    ...element.flats[0],
                    short_description: replaceDesc(element.flats[0].short_description),
                    description: replaceDesc(element.flats[0].description),
                    min_qty: element.flats[0].min_qty ?? 0,
                    meta_title: !!element.flats[0].meta_title ? element.flats[0].meta_title : element.flats[0].name,
                    meta_description: !!element.flats[0].meta_description ? element.flats[0].meta_description : stripTags(element.flats[0].short_description),
                    price: minimalPriceHandle("price"),
                    min_price: minimalPriceHandle("min_price"),
                    special_price: specialPriceTimeToggle(element, selectedRate, true)
                }],
                yoast_products: element.yoast_products.map((el) => {
                    return {
                        ...el,
                        yoast_facebook_title: el.yoast_facebook_title !== "" ? el.yoast_facebook_title : element.flats[0].meta_title ? element.flats[0].meta_title : element.flats[0].name,
                        yoast_facebook_description: el.yoast_facebook_description !== "" ? el.yoast_facebook_description : element.flats[0].meta_description ? element.flats[0].meta_description : stripTags(element.flats[0].short_description),
                        yoast_twitter_title: el.yoast_twitter_title !== "" ? el.yoast_twitter_title : element.flats[0].meta_title ? element.flats[0].meta_title : element.flats[0].name,
                        yoast_twitter_description: el.yoast_twitter_description !== "" ? el.yoast_twitter_description : element.flats[0].meta_description ? element.flats[0].meta_description : stripTags(element.flats[0].short_description),
                    }
                }),
                variants: element.variants.map(el => {
                    return {
                        ...el,
                        flats: [{
                            ...el.flats[0],
                            price: handlePriceCommasFormat(handleAmdPrice(selectedRate, el.flats[0].price)),
                            special_price: specialPriceTimeToggle(el, selectedRate),
                            min_price: handlePriceCommasFormat(handleAmdPrice(selectedRate, el.flats[0].min_price)),
                        }],
                        images: !!el.images.length
                            ? el.images.map(elem => `/storage/${elem}`)
                            : ["/_next/static/media/defaultpic.119a8e64.webp"],
                    }
                }),
                downloadable_links: element.type === "downloadable"
                    ? translatedDownloadableLinks("downloadable_links", "product_downloadable_link_id")
                        .sort((a, b) => (a.sort_order) - (b.sort_order))
                    : null,
                downloadable_samples: element.type === "downloadable"
                    ? translatedDownloadableLinks("downloadable_samples", "product_downloadable_sample_id")
                        .sort((a, b) => (a.sort_order) - (b.sort_order))
                    : null
            }
        })
    } catch (error) {
        throw error;
    }
};
/*** NEW RELATED PRODUCTS ***/
// WITH --> router get !!!!!!!!!!!
const Get_Related_Products = async (options, models) => {
    const {locale, limit, category_id, product_id, currency} = options;
    let productIds = [];
    try {
        const allowResponse = await allowOutOfStock(models);
        const sku = await Get_Needed_Attribute(models, "sku", "sku_option")
        const shDesc = await Get_Needed_Attribute(models, "short_description", "short_description_option")
        const desc = await Get_Needed_Attribute(models, "description", "description_option")
        const parsedSku = parseClone(sku)
        const parsedShDesc = parseClone(shDesc)
        const parsedDesc = parseClone(desc)
        const productRelations = await models.products_relations.find({parent_id: {$in: [product_id]}});
        if (productRelations.length > 0) {
            productIds = productRelations.map((e) => e.child_id).filter(id => id !== product_id);
        } else {
            const productsCategories = await models.products_categories.find({category_id: {$in: category_id}});
            productIds = productsCategories.map((e) => e.product_id).filter(id => id !== product_id);
        }
        const query = {
            "flats.locale": locale,
            "flats.status": 1,
            id: {$in: productIds},
            parent_id: null,
        }
        if (!Number(allowResponse)) {
            query["$or"] = outOfStockProductMatch
        }

        const res = await
            models
                .products_v2
                .find(query)
                .limit(limit)

        const options = {
            ...parsedSku,
            ...parsedShDesc,
            ...parsedDesc,
        }
        const clonedRes = parseClone(res);
        const filteredProductFlats = findFlatsExactLocale(clonedRes, locale)
        const updateQuantityRes = await addMinQuantityController(models, filteredProductFlats)
        // const modifiedRes = imageAddFields(updateQuantityRes, options)
        return await Promise.all(updateQuantityRes.map(async el => {
                const mainBody = {
                    ...productCurrencyToggle({
                        ...el,
                        flats: [{
                            ...el.flats[0],
                            name: el.flats[0]?.name ?? "",
                            price: handleAmdPrice(currency, el.flats[0].price),
                            special_price: specialPriceTimeToggle(el, currency, true),
                            min_price: handleAmdPrice(currency, el.flats[0].min_price),
                        }],
                    }, currency, locale),
                    images: el.images.length > 0
                        ? el.images.map(elem => `/storage/${elem}`)
                        : ["/_next/static/media/defaultpic.119a8e64.webp"]
                }
                if (el.type === "bundle") {
                    const bundle_options = await bundleProductHandle({
                        product: el,
                        models,
                        locale,
                        selectedRate: currency,
                        outStockAllow: allowResponse
                    })
                    const optionQuantity = bundle_options?.bundle_options?.map(({option_product_info}) => {
                        return option_product_info?.find(findItem => !!findItem.is_default) || {}
                    })
                    const totalPrice = optionQuantity?.reduce((total, { optionDefaultQty, price }) => {
                        return total + optionDefaultQty * price;
                    }, 0);
                    return {
                        ...mainBody,
                        flats: [{
                            ...el.flats[0],
                            name: el.flats[0]?.name ?? "",
                            price: handlePriceCommasFormat(handleAmdPrice(currency, totalPrice)),
                            min_price: handlePriceCommasFormat(handleAmdPrice(currency, totalPrice)),
                            special_price: null
                        }]
                    }
                }
                if (!!el.variants.length) {
                    return {
                        ...handleConfigPrice(mainBody, currency, locale),
                        flats: [{
                            ...el.flats[0],
                            name: el.flats[0]?.name ?? "",
                            price: handlePriceCommasFormat(handleConfigPrice(mainBody, currency, locale).flats[0].price),
                            min_price: handlePriceCommasFormat(handleConfigPrice(mainBody, currency, locale).flats[0].min_price),
                            special_price: el.flats[0].special_price ? handlePriceCommasFormat(handleConfigPrice(mainBody, currency, locale).flats[0].special_price) : null
                        }]
                    }
                }
                return {
                    ...mainBody,
                    flats: [{
                        ...el.flats[0],
                        name: el.flats[0]?.name ?? "",
                        price: handlePriceCommasFormat(handleAmdPrice(currency, mainBody.flats[0].price)),
                        min_price: handlePriceCommasFormat(handleAmdPrice(currency, mainBody.flats[0].min_price)),
                        special_price: specialPriceTimeToggle(el, currency)
                    }]
                }
            })
            .filter(el => el.id !== product_id))
        // return currencyUpdateRes.filter(({ flats: [{ product_id = 0 } = {}] = [] }) => product_id !== productId)
    } catch (err) {
        throw err;
    }
};
/*** NEW UPSELL PRODUCTS ***/

//AFTER ALL IS DONE -> WILL DO
// getUpOrCrossProd
const Get_Up_Sell_Products = async (options, models) => {
    const {locale, limit, product_id, rate, symbol} = options;
    const selectedRate = {
        exchange_rate: {
            rate,
        },
        symbol,
    }
    try {
        const allowResponse = await allowOutOfStock(models);
        const res = await models.product_up_sells.find({parent_id: {$in: product_id.split(",")}});
        if (res.length > 0) {
            const productIds = res.map((e) => e.child_id);
            const query = {
                "flats.locale": locale,
                "flats.status": 1,
                id: {$in: productIds},
                parent_id: null
            }
            if (!Number(allowResponse)) {
                query["$or"] = outOfStockProductMatch
            }
            const products = await
                models
                    .products_v2
                    .find(query)
                    .limit(Number(limit));

            const result = parseClone(products)
            const newProducts = findFlatsExactLocale(result, locale);
            const modifiedRes = imageAddFields(newProducts)
            const finalResultCurr = modifiedRes.map(el => productCurrencyToggle(el, selectedRate, locale))
            return await addMinQuantityController(models, finalResultCurr);
        } else {
            return res;
        }

    } catch (error) {
        throw error;
    }
};
//AFTER ALL IS DONE -> WILL DO
// getUpOrCrossProd
/*** NEW CROSS-SELL PRODUCTS ***/
const Get_Cross_Sell_Products = async (options, models) => {
    const {locale, limit, product_id, rate, symbol} = options;
    const selectedRate = {
        exchange_rate: {
            rate,
        },
        symbol,
    }
    try {
        const allowResponse = await allowOutOfStock(models);
        const res = await models.product_cross_sells.find({parent_id: {$in: product_id.split(",").map(Number)}});
        if (res.length > 0) {
            const productIds = res.map((e) => e.child_id);
            const query = {
                "flats.locale": {$in: [locale, "en"]},
                "flats.status": 1,
                id: {$in: productIds},
                parent_id: null
            }
            if (!Number(allowResponse)) {
                query["$or"] = outOfStockProductMatch
            }
            const result = await
                models
                    .products_v2
                    .find(query)
                    .limit(Number(limit));
            const localeProducts = findFlatsExactLocale(result, locale);
            const modifiedRes = imageAddFields(localeProducts)
            const randomProducts = [];
            const newProducts = [];

            while (randomProducts.length < modifiedRes.length) {
                let r = Math.floor(Math.random() * modifiedRes.length);
                if (randomProducts.indexOf(r) === -1) randomProducts.push(r);
            }

            for (let i = 0; i < modifiedRes.length; i++) {
                newProducts.push(modifiedRes[randomProducts[i]]);
            }
            const updateQuantityRes = await addMinQuantityController(models, newProducts)
            return updateQuantityRes.map(el => productCurrencyToggle(el, selectedRate, locale));
        } else {
            return res;
        }
    } catch (error) {
        throw error;
    }
};
const Get_Product_Options = (options, models) => {
    const {locale} = options;

    return allowOutOfStock(models).then(allowResponse => {
        return new Promise((resolve, reject) => {
            models.product_options.find().exec((err, productOptions) => {
                if (err) {
                    reject(err);
                    return;
                }

                models.product_option_values.find().exec((err, productOptionValues) => {
                    if (err) {
                        reject(err);
                        return;
                    }

                    const options = productOptions.map(option => {
                        const children = productOptionValues
                            .filter(value => value.product_option_id === option.id)
                            .map(value => {
                                let label = value.label;
                                if (value.translations && value.translations.length > 0) {
                                    const translation = value.translations.find(trans => trans.locale === locale);
                                    if (translation) {
                                        label = translation.label;
                                    }
                                }

                                return {
                                    id: value.id,
                                    label: label,
                                    admin_name: value?.admin_name,
                                    price: value.price,
                                    sort_order: value.sort_order
                                };
                            });

                        return {
                            id: option.id,
                            title: option.title,
                            product_id: option.product_id,
                            required: option.required,
                            type: option.type,
                            children: children
                        };
                    });

                    resolve(options);
                });
            });
        });
    });
};

/*** NEW CATEGORY PRODUCT PAGE ***/
const Get_New_Category_Products = async (
    slug,
    locale,
    limit = 12,
    models,
    page = 1,
    sortBy,
    findAllAttributes,
    isInfinite,
    filterValues,
    selectedRate = {code: "USD"},
    isMobile
) => {
    try {
        const outStockAllow = await allowOutOfStock(models);
        let resolveCategories = {};
        let queryParams = {
            "flats.locale": locale,
            "flats.status": 1,
        }
        if (slug && slug !== "all") {
            resolveCategories = await models.categories.findOne({slug});
            if (!resolveCategories || resolveCategories.length === 0) {
                return {notFound: true};
            }
            queryParams.category_ids = resolveCategories.id
        }
        if (!Number(outStockAllow)) {
            queryParams["$or"] = outOfStockProductMatch;
        }
        const pipeline = [{ $match: queryParams }];
        if (page === -1) { //I set page id -1 for builder component, if the id is not -1 it is for next.js
            pipeline.push({ $limit: limit });
        }
        const allItems = await models.products_v2.aggregate(pipeline)
        const parsedAllItems = parseClone(allItems);
        const objectPropertyHandle = (prId, findItemAttr) => {
            const AttrKeysArr = Object.keys(findItemAttr).slice(1);
            const AttrValuesArr = Object.values(findItemAttr).slice(1);
            const indexPrice = AttrKeysArr.findIndex(indexPriceKey => indexPriceKey === "price")
            if (indexPrice > -1) {
                AttrKeysArr.splice(indexPrice, 1)
                AttrValuesArr.splice(indexPrice, 1)
            }
            return AttrKeysArr.reduce((acc, elemKey, indexKey) => {
                if (
                    findItemAttr.hasOwnProperty(elemKey)
                    && filterValues[elemKey].split(",").find(attrValueI => {
                        if (Array.isArray(AttrValuesArr[indexKey])) {
                            return AttrValuesArr[indexKey].some(elemString => String(elemString) === String(attrValueI))
                        }
                        return attrValueI === AttrValuesArr[indexKey]
                    })
                    && acc
                ) {
                    acc.flats[0][elemKey] = findItemAttr[elemKey];
                    return acc;
                }
            }, {...prId})
        }
        const addAttrInFlats = parsedAllItems.map(i => {
            const findItemAttr = findAllAttributes?.find(elem => elem.product_id === i.id)
            if (findItemAttr) {
                objectPropertyHandle(i, findItemAttr)
            }
            return i;
        }).filter(F => F !== undefined)
        const updateQuantityRes = await addMinQuantityController(models, addAttrInFlats)
        const filteredProductFlats = findFlatsExactLocale(updateQuantityRes, locale)
        const updatedCurrResult = await Promise.all(filteredProductFlats.map(async el => {
            const mainBody = {
                ...productCurrencyToggle({
                    ...el,
                    flats: [{
                        ...el.flats[0],
                        name: el.flats[0].name ?? "",
                        price: handleAmdPrice(selectedRate, el.flats[0].price),
                        special_price: specialPriceTimeToggle(el, selectedRate, true),
                        min_price: handleAmdPrice(selectedRate, el.flats[0].min_price),
                    }],
                }, selectedRate, locale),
                images: el.images.length > 0
                    ? el.images.map(elem => isMobile ? `/storage/medium/${elem}` : `/storage/${elem}`)
                    : ["/_next/static/media/defaultpic.119a8e64.webp"]
            }
            if (el.type === "bundle") {
                const bundle_options = await bundleProductHandle({
                    product: el,
                    models,
                    locale,
                    selectedRate,
                    outStockAllow
                })
                const optionQuantity = bundle_options?.bundle_options?.map(({option_product_info}) => {
                    return option_product_info.find(findItem => !!findItem?.is_default) || {}
                })
                const totalPrice = optionQuantity?.reduce((total, {optionDefaultQty, price}) => {
                    return total + optionDefaultQty * price;
                }, 0);
                return {
                    ...mainBody,
                    flats: [{
                        ...el.flats[0],
                        price: handleAmdPrice(selectedRate, totalPrice),
                        special_price: null,
                        min_price: handleAmdPrice(selectedRate, totalPrice)
                    }]
                }
            }
            if (!!el.variants.length) {
                return handleConfigPrice(mainBody, selectedRate, locale)
            }
            return mainBody
        }))
        const finalCatProductsResult = updatedCurrResult
            .sort((a, b) => b.id - a.id)
            .sort((a, b) =>
                (sortBy && a.flats[0].min_price - b.flats[0].min_price)
                || (sortBy === false && b.flats[0].min_price - a.flats[0].min_price));

        return buildNeededData(finalCatProductsResult);
    } catch (error) {
        console.log(error, "___THIS IS ERROR MESSAGE FROM CATCH!!!")
    }
};

const Get_Products_By_Category = async (slug, locale, limit = 12, models, selectedRate = {code: "USD"}, isMobile) => {
    try {
        let resolveCategories = {};
        let queryParams = {
            "flats.locale": locale,
            "flats.status": 1,
        }
        if (slug && slug !== "all") {
            resolveCategories = await models.categories.findOne({slug});
            if (!resolveCategories || resolveCategories.length === 0) {
                return {notFound: true};
            }
            queryParams.category_ids = resolveCategories.id
        }

        const allItems = await models.products_v2.find(queryParams).limit(+limit)

        const parsedAllItems = parseClone(allItems);
        const updateQuantityRes = await addMinQuantityController(models, parsedAllItems)
        const filteredProductFlats = findFlatsExactLocale(updateQuantityRes, locale)
        const updatedCurrResult = filteredProductFlats.map(el => {
            const mainBody = {
                ...productCurrencyToggle({
                    ...el,
                    flats: [{
                        ...el.flats[0],
                        name: el.flats[0].name ?? "",
                        price: handleAmdPrice(selectedRate, el.flats[0].price),
                        special_price: specialPriceTimeToggle(el, selectedRate, true),
                        min_price: handleAmdPrice(selectedRate, el.flats[0].min_price),
                    }],
                }, selectedRate, locale),
                images: el.images.length > 0
                    ? el.images.map(elem => isMobile ? `/storage/medium/${elem}` : `/storage/${elem}`)
                    : ["/_next/static/media/defaultpic.119a8e64.webp"]
            }
            if (!!el.variants.length) {
                return handleConfigPrice(mainBody, selectedRate, locale)
            }
            return mainBody
        })

        return buildNeededData(updatedCurrResult);
    } catch (error) {
        console.log(error, "___THIS IS ERROR MESSAGE FROM CATCH!!!")
    }
};

const Get_Filtered_Category_Products = async (models, responseProductList, filterValues, {
    page,
    limit,
    selectedRate
}) => {
    const valueRes = await getInfiniteScrollValue(models);
    let isInfiniteScroll = false;
    if (valueRes) {
        isInfiniteScroll = Boolean(Number(valueRes));
    }
    const {data: responseData} = responseProductList || {}
    const newFilterData = [];
    if (Object.keys(filterValues).length > 0) {
        responseData.forEach(product => {
            const {flats, variants, type: configOrOtherType} = product
            const {min_price, max_price, special_price, price, ...flatInfo} = flats?.[0] || {}
            const configProduct = configOrOtherType === "configurable"
            if (
                filterBy(flats?.[0], filterValues, configProduct, configProduct ? variants : responseData)
                && (product.parent_id ? product.flats[0].visible_individually === 1 : true)
            ) {
                newFilterData.push({
                    ...product,
                    flats: [{
                        ...flatInfo,
                        price: handlePriceCommasFormat(handleAmdPrice(selectedRate, price)),
                        min_price: handlePriceCommasFormat(handleAmdPrice(selectedRate, min_price)),
                        special_price: specialPriceTimeToggle(product, selectedRate),
                        max_price: handlePriceCommasFormat(handleAmdPrice(selectedRate, max_price)),
                    }],
                    variants: product.variants.filter((item) => item.flats[0].visible_individually === 1)
                });
            }
        });
    } else {
        responseData.forEach(resElement => {
            const {flats: [{min_price, max_price, special_price, price, ...flatInfo}]} = resElement
            if (resElement.parent_id ? resElement.flats[0].visible_individually === 1 : true) {
                newFilterData.push(
                    {
                        ...resElement,
                        flats: [{
                            ...flatInfo,
                            price: handlePriceCommasFormat(handleAmdPrice(selectedRate, price)),
                            min_price: handlePriceCommasFormat(handleAmdPrice(selectedRate, min_price)),
                            special_price: specialPriceTimeToggle(resElement, selectedRate),
                            short_description: resElement.flats[0].short_description?.replace(/<\/?[^>]+>/gi, ""),
                            max_price: handlePriceCommasFormat(handleAmdPrice(selectedRate, max_price)),
                        }],
                        variants: resElement.variants.filter((item) => item.flats[0].visible_individually === 1)
                    }
                )
            }
        });
    }
    const skipCount = page === 1 || isInfiniteScroll ? 0 : (page - 1) * limit;
    const finalLimit = isInfiniteScroll ? limit * page : limit;
    const paginatedResults = newFilterData.slice(skipCount, skipCount + finalLimit);
    const {length: filteredDataLength} = newFilterData;
    const {length: paginatedDataLength} = paginatedResults || [];

    return {
        ...responseProductList,
        total: filteredDataLength,
        pageCount: filteredDataLength > paginatedDataLength ? Math.ceil(filteredDataLength / limit) : 0,
        isInfiniteScroll: isInfiniteScroll,
        data: paginatedResults
    }
}

const Get_Scroll_Products = async (models, type, locale, limit) => {
    try {
        const allProducts = await getNewFeatured(models, type, locale, limit)
        return imageAddFields(allProducts)
    } catch (err) {
        throw err
    }
}

/*********************** CUSTOMER GROUP PRICE ***********************/

const Get_Customer_Groups = async (models) => {
    try {
        const res = await models.product_customer_group_prices.find({});
        return parseClone(res);
    } catch (err) {
        console.log(err, "Error in -> !__! Get_Customer_Groups !__!")
    }
}

/*********************** BUILDER REQUESTS OLD VERSION ********************/
//OLD VERSION With Router get -> without currency
const Get_All_Products = (models, locale) => {
    return allowOutOfStock(models)
        .then(response => {
            return new Promise((resolve, reject) => {
                models
                    .products
                    .aggregate([
                        {
                            $lookup: {
                                from: "product_super_attributes",
                                localField: "id",
                                foreignField: "product_id",
                                as: "variants",
                            }
                        },
                        {
                            $lookup: {
                                from: "product_flat",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_flat",
                                pipeline: [{$match: {locale}}]
                            }
                        },
                        {
                            $lookup: {
                                from: "product_inventories",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_inventories"
                            }
                        },
                        {
                            $lookup: {
                                from: "product_images",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_images",
                            }
                        },
                        {
                            $lookup: {
                                from: "products_categories",
                                localField: "id",
                                foreignField: "product_id",
                                as: "categories",
                            }
                        },
                        {
                            $addFields: {
                                status: {$arrayElemAt: ["$product_flat.status", 0]},
                            }
                        },
                        {
                            $match: {
                                $or: [
                                    {status: 1},
                                    {status: "1"},
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "products",
                                localField: "id",
                                foreignField: "parent_id",
                                as: "products",
                                let: {id: "$id"},
                                pipeline: [
                                    {
                                        $lookup: {
                                            from: "product_flat",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_flat",
                                            pipeline: [{$match: {locale}}]
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_images",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_images",
                                        }
                                    },
                                    {
                                        $addFields: {
                                            status: {$arrayElemAt: ["$product_flat.status", 0]},
                                        }
                                    },
                                    {
                                        $match: {
                                            $or: [
                                                {status: 1},
                                                {status: "1"},
                                            ]
                                        }
                                    },
                                ]
                            }

                        }
                    ])
                    .then((res) => {

                        let setMinPrice;
                        let setMaxPrice;
                        if (res.length === 1) {
                            setMinPrice = setMaxPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], -1)).sort((a, b) => a - b)[0]
                        } else {
                            setMinPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], -1)).sort((a, b) => a - b)[0]
                            setMaxPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], 1)).sort((a, b) => b - a)[0]
                        }
                        let confMinPrice
                        let confMaxPrice;
                        res.map((item) => {
                            if (item.products.length > 0) { // @ts-ignore
                                confMinPrice = item.products.map((el) => findMinimalOrMaximalPrice(el.product_flat[0], -1)).sort((a, b) => a - b)[0]
                                confMaxPrice = item.products.map((el) => findMinimalOrMaximalPrice(el.product_flat[0], 1)).sort((a, b) => b - a)[0]
                            }
                            item.min_price = confMinPrice;
                        })

                        let min
                        let max
                        if ((Number(confMinPrice) > Number(setMinPrice)) || !isNaN(Number(setMinPrice))) {
                            min = setMinPrice
                        } else {
                            min = confMinPrice
                        }

                        if ((Number(confMaxPrice) > Number(setMaxPrice)) || isNaN(Number(setMaxPrice))) {
                            max = confMaxPrice
                        } else {
                            max = setMaxPrice
                        }
                        const everyThing = {
                            data: res,
                            filters: [],
                            links: {},
                            max_price: setMaxPrice || 0,
                            meta: {},
                            total: 100,
                            dispatches: {
                                setInitialMinPrice: min || 0, setInitialMaxPrice: max || 0,
                            },
                        };

                        resolve(everyThing)
                    })
                    .catch(err => reject(err))
            })
        })
}

const getNewFeaturedBuilder = async (models, type, locale, limit, allowResponse) => {
    return new Promise((resolve, reject1) => {
        models
            .products
            .aggregate(featuredOrNewAggregate(type, locale, limit))
            .then((res) => resolve(parseClone(res)))
            .catch((err) => reject1(err))
    })
}

function Get_New_And_Featured_Products_Builder(opts, models) {
    const {locale, limit} = opts
    return allowOutOfStock(models)
        .then(allowResponse => {
            return new Promise((resolve, reject) => {
                const newProducts = getNewFeaturedBuilder(models, "new", locale, limit, allowResponse)
                const featuredProducts = getNewFeaturedBuilder(models, "featured", locale, limit, allowResponse)
                const thirdSectionProducts = getNewFeaturedBuilder(models, "third_section", locale, limit, allowResponse)
                return Promise
                    .all([newProducts, featuredProducts, thirdSectionProducts])
                    .then((response) => {

                        let allHomeProducts = {
                            new: response[0],
                            featured: response[1],
                            third_section: response[2]
                        }
                        resolve(allHomeProducts);
                    })
                    .catch(err => reject(err));
            });
        })
}

/***************************  __ BUILDER_END __ ******************************/

module.exports = {
    Get_New_And_Featured_Products,
    Get_Product_For_Product_Inner_Page,
    Get_All_Products,
    Get_Related_Products,
    Get_Cross_Sell_Products,
    Get_Up_Sell_Products,
    Get_New_Category_Products,
    Get_Products_By_Category,
    Get_Searched_Products,
    Get_Product_Options,
    Get_Scroll_Products,
    Get_New_And_Featured_Products_Builder,
    Get_Customer_Groups,
    Get_Filtered_Category_Products
}
